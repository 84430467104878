import { groq } from '#imports';

export function getQuery({ limit = 30, offset = 0, tag } = {}) {
  let baseQuery = `*[_type == "news" && !(_id in path('drafts.**'))]`;

  if (tag) {
    baseQuery += ` && references([_type=="tag" && slug in ${tag}][0]._id)`;
  }

  baseQuery += ` | order(publishedAt desc) [${offset}...${limit}]
  {
    isChronicle,
    slug,
    title,
    tags[]->{..., tag->},
    publishedAt,
    titleColor
  }`;

  return groq`${baseQuery}`;
}
